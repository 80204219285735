<template>
    <div id="apps">
        <div class="tixian clear">
            <img src="../assets/qiandai2.png"/>
            <p>佣金金额</p>
            <h6>¥{{info.toWithdrawalAmout}}</h6>
            <div class="input clear">
                <van-field v-model="money" label="提现金额" placeholder="请输入提现金额" @change="changeMoney"/>
            </div>
            <div class="input clear">
                <van-field v-model="wechat" label="微信号" placeholder="请输入微信号"/>
            </div>
            <van-button type="default" @click="submit" :loading="loading" style="width: 80%;background: linear-gradient(to right, #F05C4A, #F98E49);">申请提现</van-button>
<!--            <span class="text">满足50元即可提现，并成为小团长即可提现<br/>邀请20个好友，即可成为中达人</span>-->
            <span class="sp text">可提现{{info.toWithdrawalAmout}}元，其余佣金因订单因未核销尚不支持提现</span>
        </div>
    </div>
</template>
<script>
  import {userAccountStat, userWithdrawal} from "@/api/user";
  import {Toast} from 'vant';

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        info: {},
        money: null,
        wechat: null,
        loading: false,
      }
    },
    methods: {
      loadBaseInfo () {
        userAccountStat().then(data => {
          if (data.success == true) {
            this.info = data.data
          }
        })
      },
      changeMoney () {
        if (parseFloat(this.money) > this.info.toWithdrawalAmout) {
          this.money = this.info.toWithdrawalAmout;
        }
      },
      submit() {
        if (this.wechat === null || this.wechat.length <= 0) {
          Toast('请输入微信号');
          return;
        }
        if (this.money == null || parseFloat(this.money) <= 0) {
          Toast('请输入提现金额');
          return
        }
        if (parseFloat(this.money) < 50) {
          Toast('提现金额小于50元无法提现');
          return
        }

        if (this.$store.getters.userinfo.userLevel < 1) {
          Toast('成为小团长后方可提现');
          return
        }
        this.loading = true;
        userWithdrawal({
          amount: this.money,
          wxNo: this.wechat,
        }).then(data => {
          if (data.success == true) {
            Toast('申请提现成功');
            this.$router.push('/txzhong');
          } else {
            Toast(data.msg);
          }
        }).finally(() => {
          this.loading = false
        })
      }
    },
    created () {
      this.loadBaseInfo();
    }
  }

</script>
<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    body {
        background: #F8F8F8;
    }

    .tixian {
        width: 100%;
        text-align: center;
    }

    .tixian img {
        width: 13%;
        display: block;
        margin: 16% auto 3% auto;
    }

    .tixian p {
        text-align: center;
        display: block;
        font-size: 13px;
        color: #AAAAAA;
    }

    .tixian h6 {
        text-align: center;
        display: block;
        font-size: 33px;
        color: #333333;
        margin: 7% auto 3% auto;
        font-weight: 400;
    }

    .tixian .input {
        width: 86%;
        margin: 3% auto 1% auto;
    }

    .tixian .input label {
        display: inline-block;
        float: left;
        color: rgba(16, 16, 16, 100);
        font-size: 13px;
        width: 21%;
        line-height: 30px;
        font-family: SourceHanSansSC-regular;
    }

    .tixian .input input {
        display: inline-block;
        width: calc(77% - 2px);
        float: left;
        text-indent: 5px;
        border: none;
        outline: none;
        line-height: 28px;
        border: solid 1px #D3D3D3;
        margin-left: 1%;
    }

    input::-webkit-input-placeholder {
        font-size: 11px;
        color: #888;
    }

    /*.tixian button {*/
    /*    display: block;*/
    /*    border: solid 1px #BBBBBB;*/
    /*    color: #101010;*/
    /*    font-size: 13px;*/
    /*    text-align: center;*/
    /*    line-height: 30px;*/
    /*    width: 90%;*/
    /*    margin: 10% auto 0 auto;*/
    /*    border-radius: 3px;*/
    /*    background: #fff;*/
    /*    outline: none;*/
    /*}*/

    .tixian .text {
        display: block;
        color: rgba(176, 176, 176, 100);
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        margin: 5% auto 1% auto;
        font-family: PingFangSC-regular;
    }

    .tixian span.sp {
        color: #E24545;
        margin-top: 0% !important;
    }
</style>
